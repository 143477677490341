import React, { Component } from "react"
import styled from "styled-components"

import Trolley from '../../../static/assets/Trolley.svg'

const Cart = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1vh;
    font-weight: bold;

    @media (max-width: 768px){
        margin: 1vh 2vh;
        }
`
const Dot = styled.div`
    opacity: ${props => (props.dot ? "0" : "1")};
    position: absolute;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: green;
    top: 24px;
    right: 32px;
    z-index: 1;
`

class CartSummary extends Component {
    constructor(props) {
        super(props);
    this.state = {items: false}
    }
    
    updateItemTotal = (qty) => {
        if (qty >= 1) {
        this.setState({items: this.items = true })
        }
        else {
            this.setState({items: this.items = false})
        }
    }

    componentDidMount() {
        document.addEventListener('snipcart.ready', () => {
            window.Snipcart.store.subscribe(() => {
                const count = window.Snipcart.store.getState().cart.items.count;
                    this.updateItemTotal(count)
                })
          });
           
       }

    componentDidUpdate() {
    document.addEventListener('snipcart.ready', () => {
        window.Snipcart.store.subscribe(() => {
            const count = window.Snipcart.store.getState().cart.items.count;
                this.updateItemTotal(count)
            })
        });
        
    }   

    componentWillUnmount () {
        document.addEventListener('snipcart.ready', () => {
            window.Snipcart.store.unsubscribe('snipcart.ready');
        })
    }
    

    render() {
        
        return (
            <>
                <Cart className="snipcart-summary">
                    <a href="#" className="snipcart-checkout" style={{textAlign: 'center'}} aria-label="Check Out"> <Trolley style={{fill: 'black'}}/></a>
                    {this.items ? (
                        <Dot />
                      ) : (
                        <Dot dot />
                      )} 
                </Cart>
            </>
        )
    }

}

export default CartSummary;
